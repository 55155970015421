import type { Nullable } from "vitest";
import type { FilterOperator } from "./components/VisualSQLEditor";

export interface Schema {
  catalogId: string;
  schemaName: string;
  isParametricSchema?: boolean;
}

export interface Table {
  catalogId: string;
  schemaName: string;
  tableName: string;
  isCacheable?: boolean;
  isDynamicTable?: boolean;
  isParametricTable?: boolean;
}

export interface Column {
  catalogId: string;
  schemaName: string;
  tableName: string;
  columnName: string;
  dataType: string;
  props: ColumnProps;
  parametricDetail?: ColumnParametricDetail;
}

export interface ColumnParametricDetail {
  displayName: string;
  name: string;
  required: boolean;
  defaultValue: string | number | boolean;
  desc: string;
  type: string;
}

export interface ColumnProps {
  dateTimeFormat?: `${DateTimeParts}|${DateFormat}|${TimeFormat}`;
  dataType: string;
  defaultValue: unknown;
  displayName: string;
  id: string;
  isNotNull: boolean;
  isSystem: boolean;
  isUnique: boolean;
  name: string;
  order: number;
  pinned?: boolean;
  displayScale?: NumberDisplayScale;
  type: ColumnType;
}

export enum ColumnType {
  Boolean = "BOOLEAN",
  DateTime = "TIMESTAMP",
  Decimal = "DECIMAL",
  Json = "JSON",
  Number = "BIGINT",
  Text = "VARCHAR",
  Uuid = "UUID",
  External = "EXTERNAL",
  Date = "DATE",
  TimeOnly = "TIME"
}

export enum ColumnDataTypeRegex {
  // Boolean
  Boolean = "^boolean$",

  // Integer
  Tinyint = "^tinyint$",
  Smallint = "^smallint$",
  Integer = "^integer$",
  Bigint = "^bigint$",
  Numeric = "^numeric$", // extra type, not in Trino
  Number = "^number$", // extra type, not in Trino

  // Floating-point
  Real = "^real$",
  Double = "^double$",

  // Decimal
  Decimal = "^decimal(\\(\\d+(,\\d+)?\\))?$",

  // String
  Char = "^char$",
  Varchar = "^varchar$",
  VarcharP = "^varchar\\(\\d+\\)$",
  Text = "^text$",
  Varbinary = "^varbinary$",
  Blob = "^blob$",
  Json = "^json$",

  // Date
  Date = "^date$",
  Time = "^time$",
  TimeP = "^time\\(\\d+\\)$",
  TimeWithTimeZone = "^time with time zone$",
  Timestamp = "^timestamp$",
  TimestampP = "^timestamp\\(\\d+\\)$",
  TimestampWithTimeZone = "^timestamp with time zone$",
  TimestampPWithTimeZone = "^timestamp\\(\\d+\\) with time zone$",
  TimestampWithTimeZoneP = "^timestamp with time zone\\(\\d+\\)$",
  IntervalYearToMonth = "^interval year to month$",
  IntervalDayToSecond = "^interval day to second$",

  // Structural
  Array = "^array$",
  Map = "^map$",
  Row = "^row$",

  // Network address
  IpAddress = "^ipaddress$",

  // UUID
  Uuid = "^uuid$",

  // HyperLogLog
  HyperLogLog = "^hyperloglog$",
  P4HyperLogLog = "^p4hyperloglog$",

  // SetDigest
  SetDigest = "^setdigest$",

  // QuantileDigest
  QDigest = "^qdigest$",

  // T-Digest
  TDigest = "^tdigest"
}

export interface Cell {
  name: string;
  displayName: string;
  dataType: string;
  value: unknown;
}

export type Row = Cell[];

export enum QueryType {
  AI = "AI",
  Initial = "INITIAL",
  Plain = "PLAIN",
  Visual = "VISUAL",
  Materialized = "MATERIALIZED"
}

export interface Query {
  id: string;
  name: string;
  displayName: string;
  inputQuery: string | null;
  compiledQuery: string;
  columns: {
    dataType: string;
    displayName: string;
    name: string;
    props: { isSystem?: boolean } | null;
  }[];
  createdTime: string;
  modifiedTime: string;
  lastValidInputQuery: string;
  visualInput: VisualSQLQuery | null;
  aiText: string | null;
  queryType: QueryType;
  semanticCatalogList?: string[];
  readonly?: boolean;
}

export enum CatalogType {
  Airtable = "AIRTABLE",
  Instagram = "INSTAGRAM",
  BigQuery = "PEAKA_BIGQUERY",
  BigQueryLegacy = "BIGQUERY",
  Cassandra = "CASSANDRA",
  Elasticsearch = "ELASTIC_SEARCH",
  GenericRest = "GENERIC_REST",
  GoogleSheets = "GSHEETS",
  GoogleAds = "GOOGLE_ADS",
  GoogleAnalytics = "GOOGLE_ANALYTICS",
  GitHub = "GITHUB",
  HubSpot = "HUBSPOT",
  Internal = "INTERNAL",
  MariaDB = "PEAKA_MARIADB",
  MariaDBLegacy = "MARIADB",
  Mongo = "MONGODB",
  MongoDB = "PEAKA_MONGODB",
  MySQL = "PEAKA_MYSQL",
  MySQLegacy = "MYSQL",
  MSSQL = "PEAKA_MSSQL",
  MSSQLLeagacy = "MSSQL",
  Neo4J = "NEO4J",
  Firebase = "FIREBASE",
  Oracle = "PEAKA_ORACLE",
  OracleLeagacy = "ORACLE",
  Postgres = "PEAKA_POSTGRES",
  PostgresSQL = "POSTGRES",
  Redshift = "PEAKA_REDSHIFT",
  RedshiftLegacy = "REDSHIFT",
  SAPHANA = "PEAKA_SAPHANA",
  SAPHANALegacy = "SAPHANA",
  SingleStore = "PEAKA_SINGLESTORE",
  SingleStoreLegacy = "SINGLESTORE",
  Stripe = "STRIPE",
  PeakaJDBC = "PEAKA_JDBC",
  SalesForceProgress = "PEAKA_SALESFORCE",
  SalesForceProgressLegacy = "SALESFORCE",
  Aurora = "PEAKA_AURORA",
  AuroraLegacy = "AURORA",
  Snowflake = "PEAKA_SNOWFLAKE",
  SnowflakeLegacy = "SNOWFLAKE",
  Teradata = "PEAKA_TERADATA",
  TeradataLeagacy = "TERADATA",
  Databricks = "PEAKA_DATABRICKS",
  DatabricksLegacy = "databricks",
  ClickHouse = "PEAKA_CLICKHOUSE",
  ClickHouseLegacy = "CLICKHOUSE",
  UpstashVector = "UPSTASH_VECTOR",
  Excel365 = "EXCEL365",
  Odoo = "ODOO",
  Qdrant = "QDRANT",
  Pinecone = "PINECONE",
  Weaviate = "WEAVIATE",
  Dynamics365 = "DYNAMICS_365",
  Semantics = "SEMANTIC"
}

export enum CatalogSubType {
  GoogleAnalytics = "google_analytics",
  Jira = "jira",
  TableExternalRest = "table_external_rest",
  Segment = "segment",
  GoogleAds = "google_ads",
  Zoom = "zoom"
}

export interface Catalog {
  displayName: string;
  id: string;
  queryName: string;
  type: CatalogType;
  subType: CatalogSubType | string;
  props: { extraDashParams?: string } & { [key: string]: unknown };
}

export type NumberDisplayScale = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export enum DateTimeParts {
  Date = "Date",
  DateTime = "DateTime",
  Time = "Time"
}

export enum DateFormat {
  Local = "Local",
  Friendly = "Friendly",
  US = "US",
  European = "European",
  ISO = "ISO"
}

export enum TimeFormat {
  h12 = "h12",
  h24 = "h24"
}

export interface VisualSQLQueryFromItem {
  catalogId: string;
  schemaName: string;
  tableName: string;
}

export type VisualSQLQueryColumn = Omit<Column, "dataType" | "props"> & {
  dataType: string | null;
  props: ColumnProps | null;
};

export interface VisualSQLQuerySelectItem {
  column: VisualSQLQueryColumn;
  function: string | null;
}

export type VisualSQLQueryFilterOperatorType = "and" | "or";

export type VisualSQLQueryFilterOperator = {
  [key in VisualSQLQueryFilterOperatorType]?: (
    | VisualSQLQueryFilterOperator
    | VisualSQLQueryFilterOperand
  )[];
};

export type VisualSQLQueryFilterOperand =
  | [VisualSQLQuerySelectItem, FilterOperator, []]
  | [VisualSQLQuerySelectItem, FilterOperator, [unknown]]
  | [VisualSQLQuerySelectItem, FilterOperator, [unknown, unknown]];

export interface VisualSQLQueryOrderByItem {
  column: VisualSQLQuerySelectItem["column"];
  order: "asc" | "desc";
}

export interface VisualSQLQuery {
  columns: VisualSQLQuerySelectItem[];
  filters: VisualSQLQueryFilterOperator | null;
  from: VisualSQLQueryFromItem[];
  limit: number;
  offset: number;
  orderBy: VisualSQLQueryOrderByItem[] | null;
  rows: unknown;
}

export interface ExecutionResult {
  columns: Column[];
  data: Row[];
}

export type GenericRestMetadata = GenericRestCatalogMetadata;

export interface GenericRestCatalogMetadata {
  subType: string;
  modifiedTime: string;
  schemas: GenericRestSchemaMetadata[];
}

export interface GenericRestSchemaMetadata {
  schemaName: string;
  tables: GenericRestTableMetadata[];
}

export interface GenericRestTableMetadata {
  tableName: string;
  isCacheable: boolean;
  isDynamicTable: boolean;
  columns: GenericRestColumnMetadata[];
}

export interface GenericRestColumnMetadata {
  columnName: string;
  props: { dataType: string; isSystem: boolean };
  parametricDetail?: GenericRestColumnMetaParametricDetail;
}

export interface GenericRestColumnMetaParametricDetail {
  displayName: string;
  name: string;
  required: boolean;
  defaultValue: string | number | boolean;
  desc: string;
  type: string;
}

export enum CacheScheduleType {
  Basic = "BASIC",
  Cron = "CRON",
  None = "NONE"
}

export enum CacheType {
  Incremental = "INCREMENTAL",
  FullRefresh = "FULLREFRESH"
}

export enum CacheActionLogType {
  CACHE_CREATED = "CACHE_CREATED",
  FULL_REFRESH_CACHE_STARTED = "FULL_REFRESH_CACHE_STARTED",
  FULL_REFRESH_CACHE_CANCELLED = "FULL_REFRESH_CACHE_CANCELLED",
  FULL_REFRESH_CACHE_RETRIED = "FULL_REFRESH_CACHE_RETRIED",
  FULL_REFRESH_CACHE_FAILED = "FULL_REFRESH_CACHE_FAILED",
  FULL_REFRESH_CACHE_COMPLETED = "FULL_REFRESH_CACHE_COMPLETED",
  FULL_REFRESH_CACHE_SCHEDULE_CREATED = "FULL_REFRESH_CACHE_SCHEDULE_CREATED",
  FULL_REFRESH_CACHE_SCHEDULE_UPDATED = "FULL_REFRESH_CACHE_SCHEDULE_UPDATED",
  FULL_REFRESH_CACHE_SCHEDULE_DELETED = "FULL_REFRESH_CACHE_SCHEDULE_DELETED",
  INCREMENTAL_CACHE_STARTED = "INCREMENTAL_CACHE_STARTED",
  INCREMENTAL_CACHE_CANCELLED = "INCREMENTAL_CACHE_CANCELLED",
  INCREMENTAL_CACHE_RETRIED = "INCREMENTAL_CACHE_RETRIED",
  INCREMENTAL_CACHE_FAILED = "INCREMENTAL_CACHE_FAILED",
  INCREMENTAL_CACHE_COMPLETED = "INCREMENTAL_CACHE_COMPLETED",
  INCREMENTAL_CACHE_SCHEDULE_CREATED = "INCREMENTAL_CACHE_SCHEDULE_CREATED",
  INCREMENTAL_CACHE_SCHEDULE_UPDATED = "INCREMENTAL_CACHE_SCHEDULE_UPDATED",
  INCREMENTAL_CACHE_SCHEDULE_DELETED = "INCREMENTAL_CACHE_SCHEDULE_DELETED"
}

export interface CacheSchedule {
  type: CacheScheduleType;
  expression: string;
}

export interface MaterializedFormData {
  type: CacheScheduleType;
  expression: string;
  queryName: string;
}

export interface CacheSettings {
  id: string;
  appId: string;
  catalogId: string;
  schemaName: string;
  tableName: string;
  incrementalCacheSchedule?: CacheSchedule;
  fullRefreshCacheSchedule?: CacheSchedule;
}

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<{ [key in Exclude<Keys, K>]: undefined }>;
  }[Keys];

export type InitialCacheSettings = RequireOnlyOne<
  CacheSettings,
  "incrementalCacheSchedule" | "fullRefreshCacheSchedule"
>;

export enum CacheAction {
  CACHE_CREATED = "CACHE_CREATED",
  CACHE_STARTED = "CACHE_STARTED",
  CACHE_CANCELLED = "CACHE_CANCELLED",
  CACHE_RETRIED = "CACHE_RETRIED",
  CACHE_FAILED = "CACHE_FAILED",
  CACHE_COMPLETED = "CACHE_COMPLETED",
  CACHE_SCHEDULE_CREATED = "CACHE_SCHEDULE_CREATED",
  CACHE_SCHEDULE_UPDATED = "CACHE_SCHEDULE_UPDATED",
  CACHE_SCHEDULE_DELETED = "CACHE_SCHEDULE_DELETED"
}

export enum CacheActionReadable {
  CACHE_CREATED = "Cache Created",
  CACHE_STARTED = "Cache Started",
  CACHE_CANCELLED = "Cache Cancelled",
  CACHE_RETRIED = "Cache Retired",
  CACHE_FAILED = "Cache Failed",
  CACHE_COMPLETED = "Cache Completed",
  CACHE_SCHEDULE_CREATED = "Cache Scheduled",
  CACHE_SCHEDULE_UPDATED = "Cache Schedule Settings Updated",
  CACHE_SCHEDULE_DELETED = "Cache Schedule Removed"
}

export interface CacheActionLog {
  action: CacheAction;
  cacheType: CacheTypes;
  timestamp: string;
  message: string;
  isScheduled: null | boolean;
  type: CacheActionLogType;
}

export enum CacheTypes {
  FULL_REFRESH = "FULL_REFRESH",
  INCREMENTAL = "INCREMENTAL"
}

export interface CacheStatus {
  id: string;
  appId: string;
  catalogId: string;
  schemaName: string;
  tableName: string;
  requestType: CacheRequestType | null;
  status: CacheStatusType;
  cacheActionLogs?: CacheActionLog[];
  lastIncrementalCacheExecution?: CacheStatus | null;
  lastFullRefreshCacheExecution?: CacheStatus | null;
  updatedAt?: string;
  finishedAt?: string;
  progress?: {
    numberOfCachedRecords?: number;
  };
}

export enum CacheStatusType {
  Running = "RUNNING",
  Cancelled = "CANCELLED",
  Failed = "FAILED",
  Completed = "COMPLETED",
  Deleted = "DELETED",
  NotInitialized = "NOT_INITIALIZED"
}

export enum CacheRequestType {
  Sync = "SYNC",
  Resync = "RESYNC"
}

export interface CDCConfigurationItem {
  name: string;
  advanced: boolean;
  description: string;
  label: string;
  nested: CDCConfigurationItem[] | null;
  required: boolean;
  type: string;
  visible: boolean;
}

export interface CDCCacheStatus {
  id: string;
  appId: string;
  catalogId: string;
  schemaName: string;
  lastEventTime: string;
  status: CDCCacheStatusType;
}

export enum CDCCacheStatusType {
  Running = "RUNNING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  NotStarted = "NOT_STARTED",
  Paused = "PAUSED"
}

export interface SharedQueryPayload {
  queryId: string;
  displayName: string;
  appId: string;
  tags?: string[];
  desc?: string;
}

export interface ImportSharedQueryPayload {
  appId: string;
  sharedId: string;
  catalogIdList: string[];
  queryDisplayName: string;
  catalogs?: { id: string; instance: number; catalogSubType: string }[];
}

export interface UpdateSharedQueryPayload {
  displayName?: string;
  sharedQueryId: string;
  queryText?: string;
  desc?: string;
  tags?: Nullable<string[]>;
}

export interface SharedQuery {
  id: string;
  name: string;
  displayName: string;
  queryText: string;
  catSubTypesRange: CatalogSubTypeRange[];
  desc?: string;
  tags?: string[];
}

export interface CatalogSubTypeRange {
  catalogSubType: string;
  location: CatalogSubTypeLocation;
  instance: number;
}

export interface CatalogSubTypeLocation {
  column: number;
  line: number;
}
