import * as React from "react";
const SvgCodat = (props) => /* @__PURE__ */ React.createElement("svg", { width: 48, height: 48, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_12234_1330)" }, /* @__PURE__ */ React.createElement("path", { d: "M18.9064 0.138761L6.48386 8.64327C6.41163 8.78741 6.41163 8.93155 6.48386 9.00363L11.2506 12.2469C11.3229 12.3189 11.3951 12.3189 11.4673 12.2469L19.1231 6.98561C19.1953 6.98561 19.1953 6.91354 19.1953 6.84146V0.354977C19.1953 0.210833 19.0508 0.0666889 18.9064 0.138761Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M45.5577 29.6883L28.874 41.2199C28.8018 41.2919 28.8018 41.2919 28.8018 41.364V47.9226C28.8018 48.0667 29.0184 48.2109 29.0907 48.0667L45.8466 36.5352C45.9189 36.4631 45.9189 36.4631 45.9189 36.391V29.8325C45.9189 29.6883 45.7022 29.6163 45.5577 29.6883Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M36.4575 29.2559C36.5297 29.328 36.602 29.328 36.6742 29.2559L41.441 26.0126C41.5854 25.9406 41.5854 25.7243 41.441 25.6523L29.0907 17.1478C28.9462 17.0757 28.8018 17.1478 28.8018 17.2919V23.8505C28.8018 23.9225 28.8018 23.9946 28.874 23.9946L36.4575 29.2559Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.368 12.7513C2.22355 12.6793 2.0791 12.7513 2.0791 12.8955V19.454C2.0791 19.5261 2.0791 19.5982 2.15133 19.5982L18.9073 31.1297C19.0518 31.2018 19.1962 31.1297 19.1962 30.9856V24.427C19.1962 24.355 19.1962 24.2829 19.124 24.2829L2.368 12.7513Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_12234_1330" }, /* @__PURE__ */ React.createElement("rect", { width: 48, height: 48, fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
}, transform: "translate(-0.000976562)" }))));
export default SvgCodat;
